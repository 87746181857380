@import "responsive";

// Fonts
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url("../fonts/fa-solid-900.eot");
  src: url("../fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-solid-900.woff2") format("woff2"), url("../fonts/fa-solid-900.woff") format("woff"), url("../fonts/fa-solid-900.ttf") format("truetype"), url("../fonts/fa-solid-900.svg#fontawesome") format("svg"); }

.fa,
.fas {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900; }

@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import "../../admin/sass/va-modifcations";

.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.venue-map {

  overflow: scroll;
  position: relative;

  .row {
    display: flex;
    flex-wrap: nowrap;
    margin: 0;

    .row-number {
      font-weight: bold;
      margin: 5px 10px;
      flex: 0 0 20px;
    }

    .seat {
      cursor: pointer;
      width: 25px;
      height: 25px;
      border: 1px solid #8BC34A;
      margin-right: 1px;
      text-align: center;
      line-height: 24px;
      border-radius: 3px;
      background: #fff;

      width: 20px;
      flex: 0 0 20px;
      height: 20px;
      font-size: 11px;
      line-height: normal;
      text-align: center;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;

      &.disabled {
        border: 1px solid gray;
        opacity: 0.3;
        cursor: default;
      }

      &.selected {
        border: 1px solid red;
        opacity: 0.7;
      }

      span {
        color: #000 !important;
      }
    }
  }
}

.price-total {
  font-size: 26px;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}

.ticket-details {
  .col-6 {
    margin-bottom: 10px;
  }
}

.stage {
  width: 100%;
  height: 80px;
  border: 1px solid black;
  background-size: 30%;
  background-repeat: repeat;
  margin: 20px auto;
  border-radius: 3px;
  position: relative;

  &:after {
    content: 'STAGE';
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    left: calc(50% - 50px);
    margin-top: 18px;
    color: black;
    //text-shadow: 0 0 10px #607D8B, 0 0 10px #607D8B;
    width: 100px;
    text-align: center;
    display: block;
  }
}

.poster-image {
  max-width: 220px;
  width: 100%;
}

.poster-image .poster {
  //width: 200px;
  height: 300px;
  width: 100%;
  margin-right: 10px;
  background-size: contain;

  @include respond-below(sm) {
    height: auto;
  }

}

/* hex seats */
.venue-map.hex-seats {
  overflow: scroll;
  padding: 20px 0;

  .row {
    display: flex;
    flex-wrap: nowrap;
    margin: 0;

    height: 21px;

      &:nth-of-type(odd) {
        .seatsWrapper {
          padding-left: 20px;
        }
      }

      .seat {
        position: relative;
        border: none;
        flex: 0 0 16px;
        background: transparent !important;

        &.disabled {
          border: none;
          opacity: 0.3;
        }

        &:before {
          font-family: "Font Awesome 5 Pro";
          font-weight: 900;
          position: absolute;
          content: "\F312";
          font-size: 23px;
          color: inherit;
          transform: rotate(90deg);
        }

        span {
          position: relative;
          z-index: 10;
          font-size: 10px;
          align-self: center;
          top: 1px;
          color: #000 !important;
        }
    }

    .row-number {
      font-weight: bold;
      margin: 5px 10px;
      flex: 0 0 20px;
    }

    .seat {
      cursor: pointer;
      border: 0px;
      margin-right: 1px;
      text-align: center;
      line-height: 24px;
      border-radius: 3px;
      background: #fff;

      width: 21px;
      flex: 0 0 20px;
      height: 20px;
      font-size: 11px;
      line-height: normal;
      text-align: center;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;

      &.disabled {
        border: none;
        opacity: 0.3;
        cursor: default;
      }

      &.selected {
        border: 1px solid red;
        opacity: 0.7;
      }
    }
  }
}

.container {
  @include respond-below(xs) {
    padding: 0;
  }
}

.sold-out {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  text-align: center;
  font-size: 40px;
  color: red;
  line-height: 300px;
  font-weight: 600;
}

.event-box {
  .address-row {
    margin-bottom: 16px;
    div {
      display: inline-block;
      vertical-align: middle;
    }
    a {
      text-decoration: none;
      color: rgba(0,0,0,.6);
    }
  }
}

/* header */
.header-bar {
  width: 100%;
  //max-width: 1280px;
  margin: 0 auto;
  background: #208ffd;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 10px 15px -3px rgb(0 0 0 / 10%);

  .header-wrapper {
	width: 100%;
	max-width: 1180px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	flex-direction: column;

	@media all and (min-width: 600px){
	  flex-direction: row;
	}
  }

  .header-logo {
	flex: 1;
	display: flex;
	justify-content: center;

	@media all and (min-width: 600px){
	  justify-content: flex-start;
	}

	img {
	  height: 100%;
	  max-height: 45px;
	  align-self: center;
	}
  }

  .header-menu {
	flex: 1;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 45px;
	align-self: center;
	padding: 0;
	margin: 0;

	a {
	  color: white;
	  line-height: 24px;
	  margin: 0 10px;
	  font-size: 18px;
	  text-transform: uppercase;
	}
  }
}

@import "success-page";
