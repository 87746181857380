body {
  background: #f6f9fc;
}

.el-select .el-input {
  max-width: 140px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.header-line th {
  border-bottom: 2px solid #9E9E9E;
}

.navigation-drawer--right {
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, .5);
}

.application {
  font-family: 'Camphor', sans-serif;
}

/*
  Generic
 */

.error--text {
  color: inherit !important;
}

/*
  Header
 */

.application .theme--dark.toolbar {
  background-color: transparent;
  color: #000;
  box-shadow: none;
  margin-bottom: 30px;
  border-bottom: solid 1px #E4E7EB;

  .toolbar__title {
    margin-left: 120px;
  }

  .logo {
    height: 30px;
  }
}

/*
  Main menu
 */

.va-menu {
  width: 220px;
  margin-right: 40px;
  //background: #fff;
  //color: #9E9E9E;
  color: #32325d;
  align-self: flex-start;
  //box-shadow: 0 3px 8px rgba(0,0,50,.15);

  ul {
    list-style: none;
    position: relative;
  }

  ul.main-menu-level {
    padding: 20px;
  }

  .el-submenu {
    padding: 5px 10px;
  }

  .icon {
    font-size: 16px;
    color: #9E9E9E;
  }

  .el-submenu__icon-arrow {
    top: auto;
    margin-top: 10px;
  }

  [class^="icon-"] {
    margin: 0 5px 0 0;
    font-family: 'LinearIcons';
    font-style: normal;
    font-size: 16px;
  }

  span {
    font-size: 14px;
  }

  li {
    cursor: pointer;
    margin: 2px 0px;
  }

  li ul {
    display: none;
  }

  li.router-link-active ul {
    display: block;
  }
}

/*
  Main Container
 */
main.content {
  //margin-top: 20px;
}

.main-container-wrapper {
  flex: 0 0 calc(100% - 220px);
  max-width: calc(100% - 220px);
}

.container.main-container {
  min-width: 860px;
}

.calendar-index .container {
  //max-width: 100%;
  //min-height: 100%;
}

/*
  Card Modifications
 */

//Card Title
.v-card__title {
  padding: 15px 30px;
  color: #343944;
  font-weight: 700;
  font-size: 20px;
  //border-bottom: solid 1px #E8EAEE;
}

.v-card {
  border-radius: 4px;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, .1), 0 3px 6px 0 rgba(0, 0, 0, .07);

  //WTF?!
  background: #fff;

  h2 {
    color: #616161;
  }

  h3 {
    color: #616161;
    font-size: 1.25rem;
  }
}

/*
  Table list
 */

.v-datatable.v-table {
  margin-bottom: 0;

  th, td {
    vertical-align: middle;
    border-top: none;
  }

  a.btn--icon {
    i {
      color: #9E9E9E;
    }
  }
}

.v-datatable .table-no-data {
  text-align: center;
  margin: 20px;
  color: #adadad;
}

.theme--light .v-table tbody tr:hover:not(.datatable__expand-row), .application .theme--light.v-table tbody tr:hover:not(.datatable__expand-row) {
  background: #fafafa;
}

.v-datatable__actions__select {
  .input-group--select .input-group__input {
    padding: 0;
    border: 0;
  }
}

.theme--light .v-table tbody tr:not(:last-child), .application .theme--light.v-table tbody tr:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}

/*
  Input modifications
 */

.el-input-group {
  //margin-top: 16px;
}

.v-input {
  margin-top: 0px;
}

.input-group {
  padding: 0;
}

.input-group--text-field {
  padding: 0 0 26px 0;
}

.search.input-group {
  padding: 0;
}

.input-group--select .input-group__input {
  padding: 10px;
}

.input-group__input input {
  font-size: 14px;
  margin-top: 10px;
  margin-left: 10px;
}

.input-group__details {
  width: auto;
  min-width: 20px;
  position: absolute;
  right: 5px;
}

.v-text-field>.v-input__control>.v-input__slot:after, .v-text-field>.v-input__control>.v-input__slot:before {
  display: none;
}

.v-input--is-focused {
  .v-input__slot {

    border: 1px solid #2196F3 !important;
  }
}

.v-text-field>.v-input__control>.v-input__slot {
  transition : border 500ms ease-out, background .3s cubic-bezier(.25,.8,.5,1);
}

.v-text-field {

  .v-input__slot {
    border: 1px solid #E4E7EB;
    border-radius: 3px;

    font-size: 14px;
    height: 45px;
    padding: 6px 14px;
    border: 1px solid #E4E7EB;
    color: #343944;
    background-color: #fff;
    border-radius: 3px;
    margin-bottom: 0px;

  }

  .v-input__slot:after,
  .v-input__slot:before {
    height: 0;
  }
}

.v-text-field.v-textarea {
  .v-input__slot {
    height: auto;
  }
}

//Error Messages
.error--text {
  .v-input__slot {
    border: 1px solid #ff335a;
  }

  .v-messages {
    right: 6px;
    //bottom: 14px;
    bottom: -6px;
    background: #ff335b;
    color: white !important;
    padding: 4px 5px;
    border-radius: 3px;
    position: absolute;
    width: auto;

    &__message {
      color: white !important;
    }
  }
}

.input-group--select .input-group__selections__comma,
.v-input label {
  font-size: 14px;
  left: 10px;
  top: 6px;
}

label.v-label.v-label--active {
  font-size: 13px;
  -webkit-transform: translate(0, -7px) scale(0.75);
  transform: translate(0, -7px) scale(0.75);
  padding: 0 5px;
  margin-left: -5px;
  top: -6px;
  background: #fff;
}

.v-input--is-label-active.v-text-field
.v-input--is-label-focused.v-text-field{

  input, textarea {
    padding: 14px 0 0px;
  }
}

.input-group--text-field:not(.input-group--single-line).input-group--focused:not(.input-group--textarea) label, .input-group--text-field:not(.input-group--single-line).input-group--placeholder:not(.input-group--textarea) label,
.input-group--text-field.input-group--dirty.input-group--select label, .input-group--text-field.input-group--dirty:not(.input-group--textarea) label {
  font-size: 13px;
  -webkit-transform: translate(0, -7px) scale(0.75);
  transform: translate(0, -7px) scale(0.75);
}

.input-group--text-field.input-group--multi-line textarea {
  padding-top: 15px;
  padding-left: 10px;
}

.input-group.input-group--single-line.input-group--dirty label, .input-group.input-group--solo.input-group--dirty label {
  display: block;
}

/*
  Button Modifications
 */

a.v-btn:hover,
a.btn:hover {
  text-decoration: none;
}

.btn--round {
  border-radius: 3px;
}

.v-card__title .title-action {
  position: absolute;
  right: 0;
  top: 10px;
}

/*
  Dropzone
 */

.vue-dropzone {
  border: 1px dashed #BEC5CB !important;
  border-radius: 3px;
}

/*
  Switch
 */

.input-group.input-group--selection-controls .input-group__input {
  justify-content: inherit;
}

/* Tabs */

.va {
  .v-tabs__bar {
    border-radius: 4px;
    box-shadow: 0 7px 14px 0 rgba(50, 50, 93, .1), 0 3px 6px 0 rgba(0, 0, 0, .07);
  }

  .v-tabs__slider-wrapper {
    top: 0px;

    .v-tabs__slider {
      height: 3px;
    }
  }

  .v-tabs__container {
    justify-content: space-around;
  }

  .v-tabs__item {
    text-transform: capitalize;
  }
}

.va-tabs {
  display: flex;
  justify-content: space-around;
  justify-items: center;

  .item {
    text-align: center;
    cursor: pointer;
    flex-grow: 1;
    box-shadow: rgb(103, 114, 229) 0px 3px inset, rgb(230, 235, 241) -1px 0px inset;
  }
}

.box {
  background-color: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
}

// Because of the box-shadow, use with caution
.v-tabs__items {
  margin: -10px;
  padding: 10px;
}

/* Empty State */

.empty-state {

  .btn {
    margin-left: 0px;
  }

  .container {
    position: relative;
    height: 300px;
  }

  .empty-image {
    width: 100%;
    position: relative;
  }

  .empty-cta {
    position: absolute;
    width: 300px;
    top: 100px;
    z-index: 3;
  }

  .not-empty {
    position: absolute;
    top: -170px;
  }
}

/*
  Sidebar
 */

.sidebar {

  &__close-btn {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  &__close-btn:hover {
    position: absolute;
  }

  &__save-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 6px 0;
    width: 100%;
    background-color: #3f51b5 !important;
    border-color: #3f51b5 !important;
  }

  .sidebarTitle {
    padding: 6px 24px 16px 24px;
    border-bottom: 1px solid gainsboro;
    margin: 10px 0;
  }

  .sidebar-wrapper {
    padding: 24px;
  }
}

/*
  Dummy Grid
 */

.fake-row {
  width: 100%;
  height: 6px;
  background: #bdbdbd;
  border-radius: 5px;
  margin-bottom: 10px;
}

/*
  Grid helpers
 */

.row-space {
  width: 100%;
  display: block;
  height: 24px;
}

/*
  Schedule table > Service Settings
 */

.schedule-table {
  width: 100%;
  margin-top: 36px;

  thead {
    th {
      font-weight: normal;
      color: #9E9E9E;
      text-align: center;
      text-transform: uppercase;
      border-bottom: 1px solid #f5f5f5;
    }
  }

  tbody {
    text-align: center;
  }

  .chip__content {
    background: #7480ce;
  }
}

/*
  Router transition
 */

.fade-enter-active, .fade-leave-active {
  transition-property: opacity;
  transition-duration: .25s;
}

.fade-enter-active {
  transition-delay: .25s;
}

.fade-enter, .fade-leave-active {
  opacity: 0
}

/* Timetable */

#calendar {
  .fc-event {
    margin: 0 5px;
    padding: 5px 10px;
    background: #fff;
    border: 1px solid #d6d7d8;
    -webkit-box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
    box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
    height: 60px;

    .badge {
      text-transform: capitalize;
      color: white;

      &__prepaid {
        background-color: material-color("light-green");
      }

      &__paid {
        background-color: material-color("light-green");
      }

      &__confirmed {
        background-color: material-color("orange");
      }

      &__unconfirmed {
        background-color: material-color("pink");
      }

      &__canceled {
        background-color: material-color("grey");
      }
    }
  }

  .fc-time-grid .fc-slats td {
    height: 35px;
  }

  tr:not(.fc-minor) > td {
    border-top: 1px dashed #eeeeee;
  }

  tbody, td {
    border-color: transparent;
    //background-image: repeating-linear-gradient(45deg, transparent, transparent 5px, #EEEEEE 0px, #EEEEEE 10px);
  }

  td.fc-today {
    background: #d2d2d2;
    background-image: repeating-linear-gradient(45deg, transparent, transparent 5px, #EEEEEE 0px, #EEEEEE 10px);
  }

  td.fc-day.fc-widget-content {
    //background: white;
  }

  h3 {
    font-size: 1.2em;
    color: #2196F3;
    margin-bottom: 0px;
  }
}


.dashboard {
  .v-card.tip {
    color: #616161;

    h2 {
      font-size: 1.6rem;
    }

    .col.action-btns {
      flex: 0;
    }
  }
}

.v-date-picker-table {
  max-width: 330px;
  margin: 0 auto;
}

.v-text-field {
  padding-top: 0px;
}

.v-select__selection--comma {
  margin: 12px 4px 0px 0;
}

.v-btn--floating.v-btn--small.tiny {
  height: 20px;
  width: 20px;
}

/* bootstrap */

/* remove spacing between middle columns */
.row.gutter-1 [class*='col-']:not(:first-child):not(:last-child) {
  padding-right:10px;
  padding-left:10px;
}
/* remove right padding from first column */
.row.gutter-1 [class*='col-']:first-child {
  padding-right:10px;
}
/* remove left padding from first column */
.row.gutter-1 [class*='col-']:last-child {
  padding-left:10px;
}

/* stepper */
.v-stepper {
  border-radius: 4px;
  -webkit-box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07), 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  background: #fff;
}

.admin .venue-map .invisible {
  font-size: 0.1em !important;
  opacity: 0.1 !important;
  visibility: visible !important;
}
