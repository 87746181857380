.success-page {
  .green-check {
    max-width: 150px;
    margin: 20px auto;
  }
  .success-box-wrapper {
    max-width: 800px;
    margin: 20px auto;
    box-shadow: 0px 10px 15px -3px rgb(0 0 0 / 10%);
    padding: 20px;
    border-radius: 8px;
    background: white;
    text-align: center;
  }

  .button {

  }

  /* CSS */
  .button-28 {
    appearance: none;
    background-color: transparent;
    background-color: #1A1A1A;
    border: 2px solid #1A1A1A;
    box-sizing: border-box;
    color: #fff;
    /*color: #3B3B3B;*/
    cursor: pointer;
    font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    min-height: 60px;
    min-width: 0;
    outline: none;
    padding: 16px 24px;
    text-align: center;
    text-decoration: none;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 100%;
    will-change: transform;
    max-width: 300px;
    display: flex;
    margin: 10px auto 0 auto;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
  }

  .button-28:disabled {
    pointer-events: none;
  }

  .button-28:hover {
    text-decoration: none;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .button-28:active {
    box-shadow: none;
    transform: translateY(0);
  }

  .button-28 svg {
    margin-left: 10px;
  }
}

.header-bar .header-menu {
  list-style: none;
}

.header-bar .header-menu a {
  font-weight: bold;
  color: white;
  line-height: 24px;
  margin: 0 10px;
  font-size: 18px;
  text-transform: uppercase;
  font-family: Roboto,sans-serif;
}
